.dropdown-content {
  display: none;
  position: absolute;
  
  min-width: 160px;
  z-index: 1;
  margin-top: 1px;
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 0.3s ease;
}

li:hover .dropdown-content {
  display: block;
  margin-top: 0;
  opacity: 1;
}

@media (max-width: 767px) {
  .custumMarigin {
    margin-left: auto;
  }
}

