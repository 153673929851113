.custom-list-disc {
    list-style-type: disc; /* Add disc markers (dots) */
    padding-left: 1.25rem; /* Adjust the indentation as needed */
  }

  .sidebar-slide-in {
    opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 1s ease; 
  }

 .sidebar-slide-in.show {
  opacity: 1; /* Change opacity to 1 when the sidebar is shown */
}
