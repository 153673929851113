@media (max-width: 768px) {
  .mobile {
    display: flex;
    width: 55%;
    height: 100%;
  }
  .mobilewhiteSize {
    width: 100%;
    height: 60px;
    display: flex;
  }
  .widthFullForOrders {
    width: 100%;
  }
  .naviTopDown {
    display: flex;
    flex-direction: column;
  }
  .felxColumnforMobile{
    display: flex;
    flex-direction: column;
  }
  .naviTextHidden {
    display: none;
  }
  .photoNameCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    padding-top: 10px;
    padding-bottom: 4px;
  }
  .Name {
    margin-bottom: 14px;
    margin-left: 12px;
    font-size: large;
  }
  .iconsWidth {
    width: 50px;
  }
  .borderIcons {
    border-bottom-width: 4px;
    border-bottom-color: rgb(249 115 22 / var(--tw-border-opacity));
  }
  .dashboardWidth {
    width: 100%;
    margin-top: 50px;
    height: 100vh;

  }
  .firstRowWidth {
    width: 40%;

  }
  .lastrowWidth {
    margin-top: 20px;

  }
  .heightForDiv {
    margin-left: 20px;
  }
  /* .mariginTop {
    margin-top: 20px;
  } */
  .flexForsmall{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .flexOrderForSmall {
    order: 9999;
  }
  .checkOutwidthForSmall {
    width: 100%;
  }
  .cartWidth {
    width: 100%;
    
  }
  .rowMarigin {
    margin-left: 2px;
  }
  .checkoutvarticalPad {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .EditProfileWidth {
    width: 100%;
  }
  

}
@media (min-width: 768px) {
    .EditProfileWidth {
        width: 55%;
    }
    /* .editProfileScroll {
        overflow-y: auto; 
        height: 400px;
    } */
    .flexForsmall {
        width: 65%;
    }
  .mobilewhiteSize {
    width: 28%;
    height: 400px;
  }
  .dashboardlgWidth {
    width: 55%;
  }
  .dashboardWidth {
    height: 100vh;
  }
  .firstRowWidth {
    width: 46%;
  }
  .heightForDiv {
    height: 170px;
  }
  .cartWidth {
    width: 480px;
  }

}
